import React, { useState, useEffect } from 'react';
import './video.scss';
import { useParams, useHistory, Redirect } from 'react-router-dom';

// components
import { MdAccessTime } from 'react-icons/md';
import SiteLoader from '../../components/SiteLoader';
import Spinner from 'react-spinner-material';
import Navigation from '../../components/Navigation';
import Button from '../../components/Button';

export default function Video () {
    const { id } = useParams();
    const [pageReady, setPageReady] = useState([]);
    const [video, setVideo] = useState([]); 

    const user = JSON.parse(localStorage.getItem('livses'));

    const fetchVideo = async () => {
        await fetch('https://dispine.com/MediaApp/api/video', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                apiKey: 'apikey-0',
                function: 'getVideo',
                params: id + ',' + '000',
            }),
        })
        .then(responsejson => responsejson.json())
        .then(data => {
            setVideo(data);
            setPageReady(true);
        });
    }

    const RenderVideo = () => {
        return (
            <>
                {
                    video.length !== null && pageReady === true ?
                    <div className='videoRoot'>
                        <div className='videoPlaceholder'></div>
                        <div className='videoContainer'>
                            <div className='video'>
                                <iframe src={"https://player.vimeo.com/video/" + video[0].vid + "?color=ff9933&title=0&byline=0&portrait=0"} width="960" height="480" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            </div>
                            
                        </div>
                    </div>
                    :
                    null
                }
            </>
        );
    }

    useEffect(() => {
        fetchVideo();
    }, []);

    return (
        <>
        {
            id !== '1' ?
            <div className='root'>
                <Navigation user={user} currentRoute='' />
                <div className='container'>
                    <RenderVideo />
                </div>
            </div>
            :
            <Redirect to='/' />
        }
        </>
    );
}