import React, { useState, useEffect } from 'react';
import './Navigation.scss';

// components
import { Button, Dropdown } from 'react-bootstrap';

export default function Navigation (props) {
    const routes = [
        { name: 'Mediathek', route: '/mediathek' },
        { name: 'Livestream', route: '/livestream' },
    ];

    const renderNavigationItem =  (route) => {
        return (
            <>
            {
                props.currentRoute === route.name ?
                <div className='navigationItemCurrent'>
                    <a href={route.route}>{ route.name }</a>
                </div>
                :
                <div className='navigationItem'>
                    <a href={route.route}>{ route.name }</a>
                </div>
            }
            </>
        );
    };

    return (
        <>
            <div className='navigation'>
                <div className='navigationLogo'>
                    <img className='navigationLogoSrc' src='https://livetrainer.tv/LiveTrainer/images/Logo_neu.png' alt='LiveTrainer.tv Logo' />
                </div>
                <div className='navigationMenu'>
                    { routes.map((route) => renderNavigationItem(route)) }
                    <div className='navigationAccount'>
                        <div className='accountUsername'>
                            { props.user.user.username }
                        </div>
                        <div className='accountEmail'>
                            { props.user.user.email }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}