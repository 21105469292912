import React, { useState, useEffect } from 'react';
import './mediathek.scss';

// components
import { Redirect } from 'react-router-dom';
import { MdAccessTime } from 'react-icons/md';
import SiteLoader from '../../components/SiteLoader';
import Spinner from 'react-spinner-material';
import Navigation from '../../components/Navigation';
import Button from '../../components/Button';

var moment = require('moment');
//var Vimeo = require('vimeo').Vimeo;
//var client = new Vimeo('cfb974450ae2d8cccb19bdd1d59ac37e9dd7b706', 'a9ZOZoZTwP3cTY80hL/5oF3plbaz/kSpuFaudDGqvD/crgtDfpkPuQr1tAaKY66INVbUZL+m+x1zzG3r5hnvrIbKJ4+xzM/2BI0Hy4ayEGRidOMcGLo/Y1ek1bo1w6I1', 'fe5d861be5f91e217f3504080f937dc3');

export default function Mediathek (props) {
    const [contentReady, setContentReady] = useState(false);
    const [sidebarReady, setSidebarReady] = useState(false);
    const [categories, setCategories] = useState([]);
    const [videos, setVideos] = useState([]);
    //localStorage.clear();
    const user = JSON.parse(localStorage.getItem('livses'));

    const fetchCategories = async () => {
        await fetch('https://dispine.com/MediaApp/api/video', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                apiKey: 'apikey-0',
                function: 'getCategories',
                params: '',
            }),
        })
        .then(responsejson => responsejson.json())
        .then(data => {
            setCategories(data.reverse());
            setSidebarReady(true);
        });
    }
    
    const fetchVideos = async () => {
        await fetch('https://dispine.com/MediaApp/api/video', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                apiKey: 'apikey-0',
                function: 'getVideos',
                params: '',
            }),
        })
        .then(responsejson => responsejson.json())
        .then(data => {
            setVideos(data.reverse());
            setContentReady(true);
        });
    }

    const RenderSidebar = () => {
        const renderCategory = (item) => (
            <div key={item.name} className='sidebarCategory'>
                { item.name }
                adasdasd
            </div>
        );

        return (
            <div className='sidebar'>
                {
                    sidebarReady === true ?
                    null
                    //categories.map((category) => renderCategory(category))
                    :
                    <div className='sidebarLoader'>
                        <Spinner radius={30} color={"#e1e1e1"} stroke={4} visible={true} />
                    </div>
                }
            </div>
        );
    }

    const RenderContent = () => {
        return (
            <div className='content'>
                <div className='contentContainer'>
                { 
                    videos.length !== 0 ?
                    videos.map((item) => RenderItem(item))
                    :
                    null
                }
                </div>
            </div>
        );
    }

    const navigateToItem = (vid) => {
        props.history.push('/video/' + vid);
        //onClick={() => navigateToItem(item.vid)}
    }

    const RenderItem = (item) => {
        return (
            <div key={item.id} className='item'>
                <div onClick={() => navigateToItem(item.vid)}  className='itemData'>
                    <div className='imageContainer'>
                        <img src={item.thumbnail} className='itemThumbnail' alt={item.name + ' thumbnail'} />
                        <div className='itemThumbnailOverlay'>


                        </div>
                    </div>
                    <div className='detailsContainer'>
                        <div className='itemDate'>{moment(item.uploadDate).format('DD.MM.YYYY')}</div>
                        <div className='itemTitle'>{item.title}</div>
                        <div className='itemDuration'>
                            <MdAccessTime size='1.2em' className='icon' />
                            {moment.utc(item.duration*1000).format('HH:mm:ss')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        fetchCategories();
        fetchVideos();
    }, []);

    return (
        <>
            <div className='root'>
                <Navigation user={user} currentRoute='Mediathek' />
                <div className='container'>
                    <RenderSidebar />
                    <RenderContent />
                </div>
            </div>
        </>
    );
}