import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import SignIn from "../pages/SignIn";

import Mediathek from "../pages/Mediathek";
import Videos from "../pages/Videos";
import Video from "../pages/Video";

export default function Routes () {
    return (
        <Switch>
            <Route path="/" exact component={SignIn} />

            <Route path="/mediathek" component={Mediathek} isPrivate />
            <Route path="/video/:id" component={Video} isPrivate />
            <Route path="/videos" component={Videos} isPrivate />
            {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
            <Route component={SignIn} />
        </Switch>
    );
}
