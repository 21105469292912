import React, { useState, useEffect } from 'react';
import './Button.scss';

// components
import Spinner from 'react-spinner-material';

export default function Button (props) {
    const {
        title,
        onClick,
        shouldLoading,
    } = props;

    return (
        <>
            {
                shouldLoading === false ?
                <button className='button' value='Anmelden' type='button' onClick={onClick}>{title}</button>
                :
                <button disabled className='button' value='Anmelden' type='button' onClick={onClick}>
                    <Spinner radius={15} color={"#fff"} stroke={1} visible={true} />
                </button>
            }
        </>
    );
}