import React, { useState, useEffect } from 'react';
import './SiteLoader.scss';

// components
import Spinner from 'react-spinner-material';

export default function SiteLoader () {
    return (
        <div className='loader'>
            <Spinner radius={60} color={"#F9A02C"} stroke={2} visible={true} />
        </div>
    );
}