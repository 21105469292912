import React, { useState, useEffect } from 'react';
import './signin.scss';

// components
import { useHistory } from 'react-router-dom';
import SiteLoader from '../../components/SiteLoader';
import Button from '../../components/Button';

export default function SignIn () {
    const [isReady, setReady] = useState(false);
    const [proceed, setProceed] = useState(false);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const onChangeUser = event => setUser(event.target.value);
    const onChangePassword = event => setPassword(event.target.value);
    
    let history = useHistory();

    const signin = async () => {
        setError('');
        setProceed(true);
        if (user.length !== 0 && password.length !== 0) {
            console.log('TEST LOG');
            const nonceData = await getNonce('user', 'generate_auth_cookie');
            console.log(nonceData);
            await loginUser(nonceData);

            //localStorage.setItem('livses', 'test1');
            setProceed(false);
        } else {
            setError('Bitte fülle alle Felder aus');
            setProceed(false);
        }
    }

    const loginUser = async (nonceData) => {
        const proxy = 'https://cors-anywhere.herokuapp.com/';
        await fetch(proxy + 'https://www1.livetrainer.tv/490741vcdsghzter23/user/generate_auth_cookie', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `username=${user}&password=${password}&seconds=10000000&nonce=${nonceData.nonce}`,
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.status !== 'error') {
                // successful login
                //setError(data);
                const userData = JSON.stringify(data);
                
                localStorage.setItem('livses', userData);
                history.push('/mediathek');
                console.log(data);
                setProceed(false);
            } else {
                console.log(data);
                setError(data.error);
            }
        })
        .catch((error) => console.log(error))
    }

    const getNonce = async (controller, method) => {
        const proxy = 'https://cors-anywhere.herokuapp.com/';
        return await fetch(proxy + 'https://www1.livetrainer.tv/490741vcdsghzter23/get_nonce', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `controller=${controller}&method=${method}`,
        })
        .then((response) => response.json())
        .then((data) => {return data;})
        .catch((error) => console.log(error));
    }

    useEffect(() => {});

    useEffect(() => {
        if (isReady === false) {
            //localStorage.clear();
            setTimeout(() => {
                setReady(true);
            }, 2000);
        }
    }, [isReady]);

    return (
        <>
        {
            isReady === true ?
            <div className='root'>
                <div className='form'>
                    <div className='logo'>
                        <img className='logoSrc' src='https://livetrainer.tv/LiveTrainer/images/Logo_neu.png' alt='LiveTrainer.tv Logo' />
                    </div>
                    <input className='formInput' value={user} placeholder='Benutzername oder E-Mail-Adresse' type='text' onChange={onChangeUser} />
                    <input className='formInput' value={password} placeholder='Passwort' type='password' onChange={onChangePassword} />
                    <Button shouldLoading={proceed} title='Anmelden' onClick={signin} />
                    { error }
                </div>
            </div>
            :
            <SiteLoader />
        }
        </>
    );
}